<template>
  <div class="bk">
    <!--<van-sticky>
      <van-row class="topbak">
        <van-col span="6"></van-col>
        <van-col span="12" style="text-align: center"
          ><span class="sel1">推荐</span><span class="nor1">活动</span>
          <span class="nor1">搜索</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>-->
    <Topnav />
    <div>
      <div class="title">开通置顶</div>
      <van-collapse v-model="activeNames">
        <van-collapse-item :name="i" v-for="(item,i) in tqlist1" :key="i">
          <template #icon>
            <div class="con1">
              <van-image
                width="50px"
                height="50px"
                :src="item.ico"
              />
            </div>
          </template>
          <template #title>
            <div class="con2">
              <div class="zhid">{{item.mc}}</div>
              <div class="sm">{{item.ms}}</div>
            </div>
          </template>
          <div>特权：</div>
          <div>
            {{item.xq}}
          </div>
          <div class="sy">
            <div>有效期{{item.sc}}天，<sapn class="jg">￥{{item.jg}}</sapn></div>
            <van-button
              color="red"
              style="width: 90px; height: 35px"
              @click="concat"
              >购买</van-button
            >
          </div>
        </van-collapse-item>

        <!-- <van-collapse-item name="2">
          <template #icon>
            <div class="con1">
              <van-image
                width="50px"
                height="50px"
                :src="require('../assets/zhid.png')"
              />
            </div>
          </template>
          <template #title>
            <div class="con2">
              <div class="zhid">置顶15天</div>
              <div class="sm">开通置顶15天，尊享更多特权。</div>
            </div>
          </template>
          <div>特权：</div>
          <div>
            置顶首页15天，增加曝光量，让更多的人看到你，只有让人看到你，才会更快的脱单。
          </div>
          <div class="sy">
            <div>有效期15天，<sapn class="jg">￥30</sapn></div>
            <van-button
              color="red"
              style="width: 90px; height: 35px"
              @click="concat"
              >购买</van-button
            >
          </div>
        </van-collapse-item>

        <van-collapse-item name="3">
          <template #icon>
            <div class="con1">
              <van-image
                width="50px"
                height="50px"
                :src="require('../assets/zhid.png')"
              />
            </div>
          </template>
          <template #title>
            <div class="con2">
              <div class="zhid">置顶30天</div>
              <div class="sm">开通置顶30天，尊享更多特权。</div>
            </div>
          </template>
          <div>特权：</div>
          <div>
            置顶首页30天，增加曝光量，让更多的人看到你，只有让人看到你，才会更快的脱单。
          </div>
          <div class="sy">
            <div>有效期30天，<sapn class="jg">￥50</sapn></div>
            <van-button
              color="red"
              style="width: 90px; height: 35px"
              @click="concat"
              >购买</van-button
            >
          </div>
        </van-collapse-item> -->
      </van-collapse>

      <div class="title">开通尊贵VIP会员</div>
      <van-collapse v-model="activeNames">
        <van-collapse-item :name="i+100" v-for="(item,i) in tqlist2" :key="i">
          <template #icon>
            <div class="con1">
              <van-image
                width="50px"
                height="50px"
                :src="item.ico"
              />
            </div>
          </template>
          <template #title>
            <div class="con2">
              <div class="zhid">{{item.mc}}</div>
              <div class="sm">{{item.ms}}</div>
            </div>
          </template>
          <div>特权：</div>
          <div>{{item.xq}}</div>
          <div class="sy">
            <div>有效期{{item.sc}}天，<sapn class="jg">￥{{item.jg}}</sapn></div>
            <van-button
              color="red"
              style="width: 90px; height: 35px"
              @click="concat"
              >购买</van-button
            >
          </div>
        </van-collapse-item>

        <!-- <van-collapse-item name="5">
          <template #icon>
            <div class="con1">
              <van-image
                width="50px"
                height="50px"
                :src="require('../assets/vip.png')"
              />
            </div>
          </template>
          <template #title>
            <div class="con2">
              <div class="zhid">金钻会员</div>
              <div class="sm">开通金钻会员，尊享更多特权。</div>
            </div>
          </template>
          <div>特权：</div>
          <div>1年内不限牵线次数，红娘辅助服务50位。</div>
          <div class="sy">
            <div>有效期365天，<sapn class="jg">￥2988</sapn></div>
            <van-button
              color="red"
              style="width: 90px; height: 35px"
              @click="concat"
              >购买</van-button
            >
          </div>
        </van-collapse-item>

        <van-collapse-item name="6">
          <template #icon>
            <div class="con1">
              <van-image
                width="50px"
                height="50px"
                :src="require('../assets/vip.png')"
              />
            </div>
          </template>
          <template #title>
            <div class="con2">
              <div class="zhid">至尊会员</div>
              <div class="sm">开通至尊会员，尊享更多特权。</div>
            </div>
          </template>
          <div>特权：</div>
          <div>
            2年内不限牵线次数，红娘辅助服务100位。享受女会员优先推荐特权。
          </div>
          <div class="sy">
            <div>有效期30天，<sapn class="jg">￥5988</sapn></div>
            <van-button
              color="red"
              style="width: 90px; height: 35px"
              @click="concat"
              >购买</van-button
            >
          </div>
        </van-collapse-item> -->
      </van-collapse>
    </div>

    <div style="height: 90px"></div>
    <Footer />

    <van-overlay :show="show" @click="show = false" style="z-index: 99999">
      <div class="wrapper">
        <div class="block" @click.stop>
          <van-image
            width="160px"
            height="160px"
            :src="vxcode"
          />

          <div class="tiptxt">长按二维码添加红娘微信</div>
          <div class="tiptxt2">开通会员，飞速脱单</div>
        </div>
        <van-image
          style="margin-top: 15px"
          width="40px"
          height="40px"
          :src="require('../assets/close.png')"
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Topnav from "@/components/Topnav.vue";
import { ref, getCurrentInstance,onMounted } from "vue";
export default {
  name: "Buy",
  components: {
    Footer,
    Topnav,
  },
  setup() {
    //const { proxy } = getCurrentInstance();
    const activeNames = ref(["0"]);
    const show = ref(false);
    const concat = () => {
      // console.log('点了')
      show.value = true;
    };
    var tqlist1 = ref([]);
    var tqlist2 = ref([]);
    const { proxy } = getCurrentInstance();
    var vxcode=ref('');

    //var value1=ref("");
    //var value2=ref("");
    const gettqlist = () => {
      let mybody = {
        openid: localStorage.getItem("pnxqtel"),
      };

      proxy.$http
        .get("https://pnlove.my306.com/pnlove/gettqlist.php", {
          params: mybody,
        })
        .then(function (res) {
          console.log(res.data)
          tqlist1.value = res.data.r1;
          tqlist2.value = res.data.r2;
          // console.log(rzlist.value);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
       onMounted(() => {
      /*let id = proxy.$route.query.userid;
      if (typeof id != "undefined") {
        proxy.GLOBAL.openid = id; //有传过来的，处理下
        Notify("客户id是：" + proxy.GLOBAL.openid);
      }*/
      vxcode.value=localStorage.getItem('vxcode');
      console.log("遇见页面");
      gettqlist();
    });
    return { activeNames, show, concat, tqlist1, tqlist2, gettqlist,vxcode };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 280px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 10px;
}
.tiptxt {
  font-size: 12px;
  margin-top: 15px;
}
.tiptxt2 {
  font-size: 12px;
}

/*.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.sel1 {
  font-weight: 510;
  margin-right: 10px;
  font-size: 22px;
}
.nor1 {
  margin-right: 10px;
  font-size: 18px;
}*/
.con1 {
  height: 60px;
  width: 70px;
  display: flex;

  align-items: center;
}
.con2 {
  height: 60px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  line-height: 25px;
}
.jg {
  color: red;
  font-weight: 500;
}
.sy {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.zhid {
  font-size: 16px;
  font-weight: 500;
}
.title {
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0px 15px 0px;
  text-align: center;
}
.sm {
  color: #666666;
}
</style>
