<template>
  <div class="bk">

    <div class="totop" @click="toback">
      <div class="toleft"></div>
    </div>

    <div style="display:none;"><img src="require('../assets/555.png')" alt=""></div>

  
    <!-- <van-sticky>
      <van-row class="topbak">
        <van-col span="4" class="back" @click="back"
          ><van-image
            width="24px"
            height="24px"
            :src="require('../assets/back.png')"
        /></van-col>
        <van-col span="16" style="text-align: center">
          <span class="nor1">平南相亲在线填写</span></van-col
        >
        <van-col span="4"></van-col>
      </van-row>
    </van-sticky> -->
    <van-image
            width="100%"
            :src="require('../assets/555.png')"
        />
    <div class="mytxt">为提供一个安全、健康、精准的交友平台，请按要求逐项认真填写资料，资料提交后经审核通过后，可根据个人意愿在平南相亲公众号、小程序同步展示，并优先参加相亲活动。除必要征婚资料外，姓名、联系方式、照片、身份信息等，都必须经过本人同意，才可以发布，请大家放心填写。</div>
    <!--会员注册-->
    <van-cell-group inset>
      <van-field
        ref="user"
        style="font-size: 16px"
        size="large"
        v-model="value1"
        label="手  机："
        placeholder="请输入手机号"
      />
      <van-field
        style="font-size: 16px; margin-top: 15px"
        v-model="value2"
        clearable
        label="验证码："
        size="large"
        placeholder="请输入验证码"
      >
        <template #button>
          <van-count-down
            @click="sendsms"
            ref="countDown"
            :time="120000"
            :auto-start="false"
            :format="state == true ? 'ss 秒' : '获取验证码'"
            @finish="onFinish"
            style="
              width: 70px;
              text-align: center;
              background-color: red;
              color: white;
              font-size: 12px;
              padding: 3px 0px 3px 0px;
            "
          />
          <!--<van-button
            @click="sendsms"
            size="small"
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            >发送验证码</van-button>-->
        </template>
      </van-field>
      <van-field
       ref="user2"
        style="font-size: 16px"
        size="large"
        v-model="value3"
        label="用户名："
        placeholder="请设置用户名用于登录平台"
      />
      <van-field
        ref="pass"
        style="font-size: 16px"
        size="large"
        v-model="value4"
        label="密  码："
        placeholder="请设置不少于6位密码用于登录平台"
      />
    </van-cell-group>
    <van-row justify="center">
      <van-button
        @click="nextone"
        round
        color="#ec616a"
        style="width: 300px; margin: 50px 0px 30px 0px; font-size: 16px"
        >下一步</van-button
      >
    </van-row>
    <van-row justify="center" style="font-size: 14px; margin-bottom: 15px">
      <van-checkbox v-model="checked" shape="square" checked-color="#999999"
        >已阅读并同意</van-checkbox
      >
      注册条款 和隐私条款
    </van-row>
    <van-row justify="center" style="color:#fa8078;font-size: 16px;" @click="logout">
      返回平台首页
    </van-row>
    <div style="height:80px;background-color: white"></div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, toRefs } from "vue";
export default {
  name: "Reg",
  setup() {
    const state = ref(false);
    const countDown = ref(null);
    const active = ref(0);
    var smscode = ref("189763549823214");
    var mybody = reactive({
      value1: "",
      value2: "",
      value3: "",
      value4: "",
    });
    var smsinfo = reactive({
      tel: "",
    });
    /*var value1 = ref("");
    var value2 = ref("");
    var value3 = ref("");
    var value4 = ref("");*/
    var checked = ref(true);
    const { proxy } = getCurrentInstance();
    //console.log("值一是：" + value1.value);

    const nextone = () => {
      if (mybody.value1 == "") proxy.$toast("请输入手机号");
      else if (mybody.value2 == "") proxy.$toast("请输入验证码");
      else if (mybody.value2 != smscode.value)
        proxy.$toast("验证码不正确，请重新输入");
      else if (mybody.value3 == "") proxy.$toast("请输入用户名");
      else if (mybody.value4 == "") proxy.$toast("请输入密码");
      else if (mybody.value4.length < 6) {
        proxy.$refs.pass.focus();
        proxy.$toast("密码不少于6位，请重新输入。");
      } else {
        proxy.$http
          .post("https://pnlove.my306.com/pnlove/reg.php", mybody)
          .then(function (res) {
            console.log(res.data.r1);
            if (res.data.r1 == "1") {
              proxy.$refs.user.focus();
              proxy.$toast("此手机号已被注册过，请更换手机号。");
            } 
            else if(res.data.r2 == "1"){
              proxy.$refs.user2.focus();
              proxy.$toast("此用户名已被注册过，请更改用户名。");
            }
            else {
              localStorage.setItem('pnxqtel',mybody.value1);
              proxy.GLOBAL.regtel = mybody.value1; //电话
              proxy.GLOBAL.regname = mybody.value3; //用户名
              proxy.GLOBAL.regpass = mybody.value4; //密码
              proxy.$router.push({
                path: "/loginfo",
                query: { type: "1" }, //0是新增加
              });
            }
          })
          .catch(() => {});
      }
    };
    const sendsms = () => {
      if (state.value == true) return; //在60秒内，不允许发送
      if (mybody.value1 == "") {
        proxy.$toast("请输入手机号");
        return;
      }
      if (mybody.value1.length != 11) {
        proxy.$toast("手机号不正确，请重新输入");
        return;
      }
      smsinfo.tel = mybody.value1;
      //console.log("验证码！");
      proxy.$http
        .post("https://pnlove.my306.com/pnlove/sendsms.php", smsinfo)
        .then(function (res) {
          console.log(res.data)
          if (res.data.r2 == "1") {
            proxy.$refs.user.focus();
            proxy.$toast("此手机号已被注册过，请更换手机号。");
          } else {
            state.value = true; //已经发送，不允许再发
            proxy.$toast("验证码发送成功！");
            start();
            console.log(res.data.r1);
            smscode.value = res.data.r1; //保存验证码
          }
        })
        .catch(() => {});
    };
    const start = () => {
      countDown.value.start();
    };
    const pause = () => {
      countDown.value.pause();
    };
    const reset = () => {
      countDown.value.reset();
    };
    const onFinish = () => {
      smscode.value = "189763549823214";
      state.value = false;
      mybody.value2 = "";
      reset();
    };
    const back = () => {
      // proxy.$router.go(-1);
      proxy.$router.push({
        path: "/index",
      });
    };
    const logout = () => {
      proxy.$router.push({
        path: "/index",
      });
    };
    const toback = () => {
      //proxy.$router.go(-1);
     /* if (window.history.length <= 1) {
        proxy.$router.push({path:'/index'});
        return false;
      } else {
          proxy.$router.go(-1);
      }*/
      proxy.$router.push({path:'/index'});
    };

    return {
      active,
      checked,
      nextone,
      mybody,
      ...toRefs(mybody),
      sendsms,
      smsinfo,
      smscode,
      countDown,
      start,
      pause,
      reset,
      onFinish,
      state,
      back,
      logout,
      toback
    };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  width: 100%;
  background-color: white;
}
.bk2 {
  padding: 20px;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}
.mytxt {
  font-size: 13px;
  margin: 30px 20px 30px 20px;
  color:#fa8078;
}
.back {
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
.totop {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 9998;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: rgba(92, 89, 85, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.toleft {
  margin-left: 4px;
  content: "";
  display: inline-block;
  height: 11px;
  width: 11px;
  border-width: 0 0 3px 3px;
  border-color: #fff;
  border-style: solid;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
}
</style>
