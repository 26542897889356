<template>
  <div class="bk">
    <van-sticky>
      <van-row class="topbak">
        <van-col span="6" class="back" @click="back">
          <van-image
            width="24px"
            height="24px"
            :src="require('../assets/back.png')"
          />
        </van-col>
        <van-col span="12" style="text-align: center">
          <span class="nor1">相亲活动</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>
     <van-empty v-if="hdcount==0" image="search" description="暂无相关活动" />

    <!-- <div class="con">
      <van-list
        style="padding: 10px; box-sizing: border-box"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
      <div class="box" v-for="index in 5" :key="index">
        <van-image
        radius="5"
          width="100%"
          height="150px"
          src="http://gzqy.dayin163.com/fx1648199849131fx1645356170168.png"
        />
        <div class="hdtxt">2021年龚州情缘大型相亲活动</div>
        <div class="hdsj">活动日期：2021-12-12 15:00  剩余报名：0天</div>
        <div class="hdbn">
        <div class="bn1">平南县</div>
          <div class="bn1">活动结束</div>
        </div>
      </div>
      </van-list>
    </div> -->

<div v-else>
    <div class="mybn2">相亲活动</div>
    <div class="sqzd">最新相亲活动，赶快参与</div>
    <div class="top4">
      <van-row
        v-for="(item, index2) in artlist"
        :key="index2"
        @click="clickart(index2)"
        class="artrow"
      >
        <van-col span="7">
          <van-image
            fit="cover"
            width="100%"
            height="100px"
            radius="5px"
            :src="item.pic"
          />
        </van-col>
        <van-col span="17" class="artcol">
          <div class="artf1">{{ item.title }}</div>
          <div class="artf2">活动时间：{{ item.btime }} 
            <span class="f2" v-if="item.state==0">报名中</span>
            <span class="f2" v-else-if="item.state==1">进行中</span>
            <span class="f2" v-else>已结束</span>
          </div>
        </van-col>
      </van-row>
    </div> 
  </div>


 <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
// import Topnav from "@/components/Topnav.vue";
import { ref, getCurrentInstance, onMounted } from "vue";
export default {
  name: "Party",
   components: {
    Footer
    // Topnav,
  },
  setup() {
    const loading = ref(false);
    const finished = ref(false);
    const hdcount=ref(0);
    const { proxy } = getCurrentInstance();
    const active = ref(0);
    const mmlist = ref([]);
    let artlist = ref([]);
    var myoption = {
      openid: "",
      pagesize: 12,
      pageindex: 1,
    };
    //var value1=ref("");   :immediate-check="false"
    //var value2=ref("");
    onMounted(() => {
      //getorderlist();
        gethdlist();
      //console.log("活动来了");
    });
     const gethdlist=()=>{
        proxy.$http
        .get("https://pnlove.my306.com/pnlove/gethdlist.php", {
          params:  myoption,
        })
        .then(function (res) {
           //console.log(res.data);
          artlist.value=res.data.r1;
          hdcount.value=res.data.r2;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const getorderlist = () => {
      myoption.openid = localStorage.getItem("pnxqtel");
      //点击登录
      proxy.$http
        .get("https://pnlove.my306.com/pnlove/getscinfo.php", {
          params: myoption,
        })
        .then(function (res) {
          console.log(res.data.r2);
          loading.value = false;
          if (res.data.r2 == "0") {
            finished.value = true;
          } else {
            //console.log("记录是" + res.data);
            mmlist.value = mmlist.value.concat(res.data.r1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const back = () => {
      proxy.$router.go(-1);
    };

    const clickme = (index) => {
      //if (proxy.GLOBAL.openid == "") {
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
      } else {
        proxy.$router.push({
          path: "/userdetail",
          query: { userid: mmlist.value[index].id },
        });
      }
    };
    const onLoad = () => {
      myoption.pageindex = myoption.pageindex + 1; //触底了下一页
      //getorderlist();
      console.log("触底了");
    };
    const clickart = (index) => {
      proxy.$router.push({
        path: "/hddetail",
        query: { id: artlist.value[index].id },
      });
    };
    return {
      onLoad,
      loading,
      finished,
      clickme,
      active,
      getorderlist,
      gethdlist,
      mmlist,
      myoption,
      back,
      artlist,
      clickart,
      hdcount
    };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  width: 100%;

  background-color: #f7f7f7;
  font-size: 14px;
}
.bk2 {
  padding: 20px;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}
.mytxt {
  font-size: 20px;
  margin: 30px 20px 30px 20px;
}
.back {
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
.name {
  font-size: 16px;
}
.f1 {
  font-size: 13px;
  color: #666666;
}
.con {
  height: 100vh;
  background-color: #f7f7f7;
}
.box{
  background-color: white;
  border-radius: 5px;
  margin-bottom: 20px;
}
.hdtxt{
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin:5px
}
.hdsj{
  color: #666666;
  font-size: 12px;
text-align: center;
}
.hdbn{
  text-align: center;
}
.bn1{
  font-size: 13px;
  color: #ec616a;
  display: inline-block;
  padding: 3px;
  border: 1px solid #f7f7f7;
  margin: 10px 8px 10px 0px;
  border-radius: 5px;

}
.artcol {
  padding: 0px 10px 5px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.artf1 {
  display: -webkit-box;
  /* -webkit-box-orient: vertical; */
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;

  font-size: 20px;
  font-weight: 550;
  color:#444444;
}
.artf2 {
  font-size: 13px;
  color: #666666;
}
.top4 {
  margin-top: 10px;
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;

  background-color: white;
}
.artrow{
  margin-bottom: 15px;
}
.sqzd {
  background-color: white;
  color: #ee7f87;
  text-align: center;
  font-size: 15px;
}
.mybn2 {
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  color: #ec616a;
  background-color: white;
  padding: 20px 0px 8px 0px;
}
.f2{
  color: #ec616a;
  font-weight: 600;
  margin-left: 8px;
}
</style>
