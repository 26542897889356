<template>
  <div class="bk">
    <van-sticky>
      <van-row class="topbak">
        <van-col span="6" class="back" @click="back"
          ><van-image
            width="24px"
            height="24px"
            :src="require('../assets/back.png')"
        /></van-col>
        <van-col span="12" style="text-align: center">
          <span class="nor1">找回密码</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>

    <div class="mytxt">请输入您注册的手机号，我们将给您发送密码重置短信。</div>
    <!--会员注册-->
    <van-cell-group inset>
      <van-field
        ref="user"
        style="font-size: 16px"
        size="large"
        v-model="value1"
        label="手  机："
        placeholder="请输入手机号"
      />
      <van-field
        style="font-size: 16px; margin-top: 15px"
        v-model="value2"
        clearable
        label="验证码："
        size="large"
        placeholder="请输入验证码"
      >
        <template #button>
          <van-count-down
            @click="sendsms"
            ref="countDown"
            :time="120000"
            :auto-start="false"
            :format="state == true ? 'ss 秒' : '获取验证码'"
            @finish="onFinish"
            style="
              width: 70px;
              text-align: center;
              background-color: red;
              color: white;
              font-size: 12px;
              padding: 3px 0px 3px 0px;
            "
          />
          <!--<van-button
            @click="sendsms"
            size="small"
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            >发送验证码</van-button>-->
        </template>
      </van-field>
      <!--<van-field
        style="font-size: 16px"
        size="large"
        v-model="value3"
        label="用户名："
        placeholder="请输入用户名"
      />
      <van-field
        ref="pass"
        style="font-size: 16px"
        size="large"
        v-model="value4"
        label="密  码："
        placeholder="请输入密码"
      />-->
    </van-cell-group>
    <van-row justify="center">
      <van-button
        @click="nextone"
        round
        color="#ec616a"
        style="width: 300px; margin: 50px 0px 30px 0px; font-size: 16px"
        >提交</van-button
      >
    </van-row>
    <!-- <van-row justify="center" style="font-size: 14px; margin-bottom: 15px">
      <van-checkbox v-model="checked" shape="square" checked-color="#999999"
        >已阅读并同意</van-checkbox
      >
      注册条款 和隐私条款
    </van-row>-->
    <!--<van-row justify="center" style="font-size: 14px; padding: 0px 0px 20px 0x">
      没有帐号？点击注册
    </van-row>-->
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, toRefs } from "vue";
export default {
  name: "Findpass",
  setup() {
    const state = ref(false);
    const countDown = ref(null);
    const active = ref(0);
    var smscode = ref("189763549823214");
    var mybody = reactive({
      value1: "",
      value2: "",
      value3: "",
      value4: "",
    });
    var smsinfo = reactive({
      tel: "",
    });
    /*var value1 = ref("");
    var value2 = ref("");
    var value3 = ref("");
    var value4 = ref("");*/
    var checked = ref(true);
    const { proxy } = getCurrentInstance();
    //console.log("值一是：" + value1.value);

    const nextone = () => {
      if (mybody.value1 == "") proxy.$toast("请输入手机号");
      else if (mybody.value2 == "") proxy.$toast("请输入验证码");
      else if (mybody.value2 != smscode.value)
        proxy.$toast("验证码不正确，请重新输入");
      else {
        proxy.$http
          .post("https://pnlove.my306.com/pnlove/findpass2.php", mybody)
          .then(function (res) {
            console.log(res.data);
            // proxy.$toast("密码重置成功，请查看手机短信获取新密码！");
            proxy.$dialog
              .alert({
                title: "",
                message: "密码重置成功，请查看手机短信获取新密码！",
                theme: "round-button",
              })
              .then(() => {
                proxy.$router.push({ path: "/index" });
                // on close
              });
            /*if (res.data.r1 == "1") {
              proxy.$refs.user.focus();
              proxy.$toast("此手机号已被注册过，请更换手机号。");
            } else {
              proxy.GLOBAL.regtel = mybody.value1; //电话
              proxy.GLOBAL.regname = mybody.value3; //用户名
              proxy.GLOBAL.regpass = mybody.value4; //密码
              proxy.$router.push({
                path: "/loginfo",
                query: { type: "0" } //0是新增加
              });
            }*/
          })
          .catch(() => {});
      }
    };
    const sendsms = () => {
      if (state.value == true) return; //在60秒内，不允许发送
      if (mybody.value1 == "") {
        proxy.$toast("请输入手机号");
        return;
      }
      if (mybody.value1.length != 11) {
        proxy.$toast("手机号不正确，请重新输入");
        return;
      }
      smsinfo.tel = mybody.value1;
      //console.log("验证码！");
      proxy.$http
        .post("https://pnlove.my306.com/pnlove/findpass.php", smsinfo)
        .then(function (res) {
          if (res.data.r2 == "0") {
            //不存在
            //proxy.$refs.user.focus();
            proxy.$toast("此手机号还未被注册，请进行注册。");
          } else {
            state.value = true; //已经发送，不允许再发
            proxy.$toast("验证码发送成功！");
            start();
            console.log(res.data.r1);
            smscode.value = res.data.r1; //保存验证码
          }
        })
        .catch(() => {});
    };
    const start = () => {
      countDown.value.start();
    };
    const pause = () => {
      countDown.value.pause();
    };
    const reset = () => {
      countDown.value.reset();
    };
    const onFinish = () => {
      smscode.value = "189763549823214";
      state.value = false;
      mybody.value2 = "";
      reset();
    };
    const back = () => {
      proxy.$router.go(-1);
    };

    return {
      active,
      checked,
      nextone,
      mybody,
      ...toRefs(mybody),
      sendsms,
      smsinfo,
      smscode,
      countDown,
      start,
      pause,
      reset,
      onFinish,
      state,
      back,
    };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
}
.bk2 {
  padding: 20px;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}
.mytxt {
  font-size: 14px;
  margin: 30px 20px 30px 20px;
}
.back {
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>
