<template>
  <div class="bk">
    <van-sticky>
      <van-row class="topbak">
        <van-col span="6" class="back"> </van-col>
        <van-col span="12" style="text-align: center">
          <span class="nor1">搜索结果</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>

    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      style="padding: 15px"
    >
      <van-row
        v-for="(item, index) in mmlist"
        :key="index"
        style="font-size: 10px; border-bottom: 1px solid #f7f7f7"
        justify="center"
        align="center"
        @click="clickme(index)"
      >
        <van-col span="6">
          <van-image fit="cover" width="68px" height="68px" :src="item.imglist"
        /></van-col>
        <van-col span="12">
          <div class="name">{{ item.name }}</div>
          <div class="f1">{{ item.age }}岁 {{ item.hy }} {{ item.sg }}</div>
          <div class="f1">{{ item.xg }} {{ item.xl }}</div>
        </van-col>
        <van-col span="6" align="end">
          <!--<van-button
            @click.stop="clicksc(index)"
            round
            size="small"
            style="width: 70px; background-color: #f7f7f7"
            >取消关注</van-button
          >-->
          <van-icon name="arrow" size="24" color="#8888"/>
        </van-col>
      </van-row>
    </van-list>

    <Footer />
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Search2",
  components: {
    Footer,
  },
  setup() {
    const loading = ref(false);
    const finished = ref(false);
    var keyword = "";
    const { proxy } = getCurrentInstance();
    //const active = ref(0);
    const mmlist = ref([]);
    var myoption = {
      keyword: "",
      pagesize: 6,
      pageindex: 0,
    };
    onMounted(() => {
      keyword = proxy.$route.query.keyword;
      console.log(keyword);
      if (typeof keyword != "undefined") {
        // Notify("客户id是：" + proxy.GLOBAL.openid);
      }
      //getorderlist();
    });
    const getorderlist = () => {
      proxy.$http
        .get("https://pnlove.my306.com/pnlove/search.php", {
          params: myoption,
        })
        .then(function (res) {
          //console.log(res.data.r2);
          loading.value = false;
          if (res.data.r2 == "0") {
            finished.value = true;
          } else mmlist.value = mmlist.value.concat(res.data.r1);
          console.log(res.data);
        })
        .catch(function (error) {
          loading.value = false;
          console.log(error);
        });
    };
    /*var myoption = {
      openid: "",
      pagesize: 12,
      pageindex: 0,
    };*/

    /*const active = ref(0);
    var mybody = reactive({
      value1: "",
      value2: "",
    });
    //var value1=ref("");
    //var value2=ref("");
    const reg = () => {
      proxy.$router.push({ path: "/reg" });
    };
    const logme = () => {
      //点击登录
      if (mybody.value1 == "") proxy.$toast("请输入手机号或用户名");
      else if (mybody.value2 == "") proxy.$toast("请输入密码");
      else {
        proxy.$http
          .post("https://pnlove.my306.com/pnlove/login.php", mybody)
          .then(function (res) {
            console.log(res.data);
            if (res.data.r1 == "2") {
              proxy.$toast("手机号或用户名不存在。");
              //mybody.value1="";
              //mybody.value2="";
              proxy.$refs.user.focus();
            } else if (res.data.r1 == "0") {
              proxy.$toast("密码不正确，请重新输入。");
            } else {
              localStorage.setItem('pnxqtel',res.data.r2);//记录下返回的电话号码(不记录用户名，以电话号码为唯一)
              localStorage.setItem('userico',res.data.r3.imglist);
              localStorage.setItem('username',res.data.r3.name);
              proxy.$router.push({ path: "/index" }); //密码正确
            }
          })
          .catch(() => {});
      }
    };*/
    /* const back = () => {
      proxy.$router.go(-1);
    };*/
    const onLoad = () => {
      myoption.pageindex = myoption.pageindex + 1; //触底了下一页
      myoption.keyword = keyword;
      console.log("要查找的关键词" + myoption.keyword);
      getorderlist();
      //console.log("触底了");
    };
    const clickme = (index) => {
      //if (proxy.GLOBAL.openid == "") {
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
      } else {
        proxy.$router.push({
          path: "/userdetail",
          query: { userid: mmlist.value[index].id },
        });
      }
    };
    return {
      onLoad,
      loading,
      finished,
      mmlist,
      clickme,
    };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  width: 100%;
  height: 100vh;
  background-color: white;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}
.mytxt {
  font-size: 20px;
  margin: 30px 20px 30px 20px;
}
.back {
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
.name {
  font-size: 16px;
}
.f1 {
  font-size: 13px;
  color: #666666;
}
</style>
