<template>
  <div class="bk">
    <div ref="target"></div>
    <div class="totop" @click="toback" v-show="blshowbak">
      <div class="toleft"></div>
    </div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <div :class="blclick ? 'zhandone' : 'zhan'" @click="likeClick">
          <van-icon name="good-job-o" size="18" />
          <transition
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
          >
            <span class="addOne" v-if="isLike">+1</span>
          </transition>
          {{ num }}
        </div>
        <van-image :src="mmlist.imglist" width="100%">
          <a class="xqk" @click="clickxqk"><van-image :src="require('../assets/xqk2.png')" width="100%"></van-image>
          <span class="xqkwz">相亲卡</span></a>
        </van-image>
      </van-swipe-item>
    </van-swipe>
    <div class="mycon">
      <van-row>
        <van-col span="18">
          <div class="name">{{ mmlist.name }}</div>
          <div class="uid">ID:{{ mmlist.id }} <div class="dz">  <van-image
                width="16px"
                height="16px"
                :src="require('../assets/dz.png')"
              />{{ mmlist.adress }}</div></div>
          <!--姓名-->
          <van-tag
            class="mytag"
            color="rgba(247, 153, 167)"
            text-color="#ffff"
            size="medium"
            >{{ mmlist.age }}岁</van-tag
          >
          <van-tag
            class="mytag"
            color="rgba(125, 196, 248)"
            text-color="#ffff"
            size="medium"
            >{{ mmlist.sg }}CM</van-tag
          >
          <van-tag
            class="mytag"
            color="rgba(208, 167, 243)"
            text-color="#ffff"
            size="medium"
            >{{ mmlist.zw }}</van-tag
          >
          <van-tag
            class="mytag"
            color="rgba(214, 183, 137)"
            text-color="#ffff"
            size="medium"
            >{{ mmlist.hy }}</van-tag
          >
        </van-col>
        <van-col span="6" class="mytxt2" @click="clicksc">
          <!--<van-button
            round
            color="rgb(235, 164, 164)"
            size="small"
            style="margin-right: 5px; width: 100px"
            >举报/屏蔽</van-button
          >-->
          <van-image
            width="45"
            height="45"
            :src="
              mmlist.blsc == '0'
                ? require('../assets/fav.png')
                : require('../assets/fav1.png')
            "
          />关注
        </van-col>
      </van-row>
    </div>
    <van-tabs v-model:active="active" shrink line-width="10px" @click-tab="lookxc">
      <van-tab
        title="资料"
        name="zl"
        :title-style="
          active == 0
            ? 'color:#ec616a;font-weight:550;font-size:20px;margin-right:20px;'
            : 'margin-right:20px;font-size:17px;'
        "
      >
        <div class="mycon2">
          <div class="rzrow">
            <div class="myrow mytitle2">
              <van-image
                width="10"
                height="10"
                :src="require('../assets/line.png')"
              />
              <div>TA的认证</div>
            </div>
            <div class="rzcol" @click="morerz">
              已点亮{{ rzcount }}项认证，更多 >
            </div>
          </div>

          <van-grid :border="false" :column-num="4">
            <van-grid-item>
              <van-image
                width="40px"
                height="40px"
                :src="require('../assets/sj2.png')"
              />
              <div style="font-size: 13px; margin-top: 4px; color: #66666">
                手机认证
              </div>
            </van-grid-item>
            <van-grid-item v-show="rzlist != null && rzlist.sfrz == '1'">
              <van-image
                width="40px"
                height="40px"
                :src="require('../assets/sf2.png')"
              />
              <div style="font-size: 13px; margin-top: 4px; color: #66666">
                身份认证
              </div>
            </van-grid-item>

            <van-grid-item v-show="rzlist != null && rzlist.wxrz == '1'">
              <van-image
                width="40px"
                height="40px"
                :src="require('../assets/wx2.png')"
              />
              <div style="font-size: 13px; margin-top: 4px; color: #66666">
                微信认证
              </div>
            </van-grid-item>

            <van-grid-item v-show="rzlist != null && rzlist.zrrz == '1'">
              <van-image
                width="40px"
                height="40px"
                :src="require('../assets/zr2.png')"
              />
              <div style="font-size: 13px; margin-top: 4px; color: #66666">
                真人认证
              </div>
            </van-grid-item>

            <van-grid-item v-show="rzlist != null && rzlist.hyrz == '1'">
              <van-image
                width="40px"
                height="40px"
                :src="require('../assets/hk2.png')"
              />
              <div style="font-size: 13px; margin-top: 4px; color: #66666">
                婚况认证
              </div>
            </van-grid-item>

            <van-grid-item v-show="rzlist != null && rzlist.xlrz == '1'">
              <van-image
                width="40px"
                height="40px"
                :src="require('../assets/xl2.png')"
              />
              <div style="font-size: 13px; margin-top: 4px; color: #66666">
                学历认证
              </div>
            </van-grid-item>

            <van-grid-item v-show="rzlist != null && rzlist.zfrz == '1'">
              <van-image
                width="40px"
                height="40px"
                :src="require('../assets/house2.png')"
              />
              <div style="font-size: 13px; margin-top: 4px; color: #66666">
                住房认证
              </div>
            </van-grid-item>

            <van-grid-item v-show="rzlist != null && rzlist.ddrz == '1'">
              <van-image
                width="40px"
                height="40px"
                :src="require('../assets/dd2.png')"
              />
              <div style="font-size: 13px; margin-top: 4px; color: #66666">
                到店认证
              </div>
            </van-grid-item>
          </van-grid>

          <div class="myrow mytitle">
            <van-image
              width="10"
              height="10"
              :src="require('../assets/line.png')"
            />
            <div>基本资料</div>
          </div>
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.xb == "0" ? "男" : "女" }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.age }}岁</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.sg }}CM</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.tz }}KG</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.xz }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.sx }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.hy }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.zw }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >年收入：{{ mmlist.sr }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.xl }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >籍贯：{{ mmlist.xg }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.house }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.car }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >{{ mmlist.jieyi == "0" ? "介意离异" : "不介意离异" }}</van-tag
          >

          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="RGB(47,47,47)"
            size="large"
            >居住地：{{ mmlist.adress }}</van-tag
          >
          <van-tag
            class="mytag2"
            round
            plain
            color="#f7f7f7"
            text-color="rgb(233, 108, 108)"
            size="large"
            @click="clickmore"
            >查看更多</van-tag
          >
          <div class="myrow mytitle2">
            <van-image
              width="10"
              height="10"
              :src="require('../assets/line.png')"
            />
            <div>择偶要求</div>
          </div>
          <div class="mytxt">{{ mmlist.yaoqiu }}</div>
          <div class="myrow mytitle2">
            <van-image
              width="10"
              height="10"
              :src="require('../assets/line.png')"
            />
            <div>自我介绍</div>
          </div>
          <div class="mytxt">{{ mmlist.jianjie }}</div>
          <div class="myrow mytitle2">
            <van-image
              width="10"
              height="10"
              :src="require('../assets/line.png')"
            />
            <div>最近登录</div>
          </div>
          <div class="mytxt">最近登录时间：{{ mmlist.lasttime }}</div>
          <van-row class="mybtm">
            <van-col span="4" style="display: flex; align-items: center"
              ><van-image
                :src="mmlist.imglist"
                width="45"
                height="45"
                round
                fit="cover"
            /></van-col>
            <van-col span="13" style="display: flex; align-items: center">
              <div>
                <div class="mytxt3">开通会员飞速脱单</div>
                <div class="mytxt4">相亲成功率提升300%</div>
              </div>
            </van-col>
            <van-col
              span="7"
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
              ><van-button
                @click="buyhy"
                round
                color="rgb(236,104,122)"
                style="width: 90px; height: 30px"
                >立即开通</van-button
              ></van-col
            >
          </van-row>

          <div class="txtitle">
            <van-image
              :src="require('../assets/tip.png')"
              width="20"
              height="20"
            />温馨提示
          </div>
          <div class="txinfo">
            该会员的联系方式已完善。对TA心动吗？点击红娘为你牵线与TA取得联系并进一步沟通。
          </div>

          <div class="rzrow">
            <div class="mytitle2">
              <div>猜你喜欢</div>
            </div>
            <div class="rzcol" @click="moreyoulove">换一批 ></div>
          </div>
          <van-grid :border="false" :column-num="5" :gutter="0">
            <van-grid-item v-for="(item, i) in yllist" :key="i" @click="clickyoulove(i)">
              <van-image width="50px" height="50px" :src="item.imglist" round
                fit="cover" />
              <div style="text-align:center;width:40px;font-size: 13px; margin-top: 4px; color: #66666;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                {{ item.name }}
              </div>
              <div style="font-size: 13px; margin-top: 4px; color: #66666">
                {{ item.age }}岁
              </div>
            </van-grid-item>
          </van-grid>

          <!--  <van-row class="mybtm2">
        <van-col span="4" style="display: flex; align-items: center"
          ><van-image
            :src="require('../assets/hnpic.png')"
            width="45"
            height="45"
            round
            fit="cover"
        /></van-col>
        <van-col span="13" style="display: flex; align-items: center">
          <div>
            <div class="mytxt3">在线客服</div>
            <div class="mytxt4">对TA心动吗？</div>
            <div class="mytxt4">您可以联系在线客服进行牵线</div>
          </div>
        </van-col>
        <van-col
          span="7"
          style="display: flex; align-items: center; justify-content: flex-end"
          ><van-button
          @click="concat"
            round
            color="rgb(236,104,122)"
            style="width: 90px; height: 30px;"
            >联系客服</van-button
          ></van-col
        >
      </van-row>-->
        </div>
      </van-tab>
      <van-tab
        :title="'相册(' + picarr.length + ')'"
        name="xc"
        :title-style="
          active == 1
            ? 'color:#ec616a;font-weight:550;font-size:20px;margin-right:20px;'
            : 'margin-right:20px;font-size:17px;'
        "
      >
        <div class="mycon2" ref="getheight">
          <!-- <div
            class="myfixed"
            :style="'height:' + myH"
            v-if="userstate == '0' || userstate == '3'"
            @click="lookxc"
          >
            请完善您的资料，再查看TA的相册
          </div> -->
          <!-- <div class="mytitle">相册</div> -->
          <van-image
            fit="cover"
            :class="userstate == '0' || userstate == '3' ? 'myimg2' : 'myimg'"
            :src="item"
            width="75px"
            height="75px"
            v-for="(item, index) in picarr"
            @click="clickpic(index)"
            :key="index"
          />
        </div>
      </van-tab>
    </van-tabs>

    <div style="height: 100px"></div>
    <van-action-bar>
      <van-action-bar-icon
        style="width: 130px"
        text="送礼物"
        @click="onClickSh"
      >
        <template #icon>
          <van-image
            :src="require('../assets/gif.png')"
            width="22"
            height="22"
          />
        </template>
      </van-action-bar-icon>
      <!--<van-action-bar-icon icon="shop-o" text="店铺" @click="onClickIcon" />-->
      <van-action-bar-button type="danger" text="联系Ta" @click="concat" />
    </van-action-bar>

    <van-overlay :show="show" @click="show = false" style="z-index: 99999">
      <div class="wrapper">
        <div class="block" @click.stop="longtimeclick">
          <van-image
            width="160px"
            height="160px"
            :src="vxcode"
          />

          <div class="tiptxt">长按二维码添加红娘微信</div>
          <div class="tiptxt2">马上为你牵线，飞速脱单</div>
        </div>
        <van-image
          style="margin-top: 15px"
          width="40px"
          height="40px"
          :src="require('../assets/close.png')"
        />
      </div>
    </van-overlay>

    <div class="concat" @click="concat" v-show="blshowbak">
      <van-image
        width="21px"
        height="21px"
        :src="require('../assets/hn.png')"
      />红娘
    </div>
    <div class="totop2" @click="totop" v-show="blshowbak">
      <van-image
        width="21px"
        height="18px"
        :src="require('../assets/totop.png')"
      />顶部
    </div>

    <van-popup
      style="z-index: 9999; height: 430px"
      v-model:show="show2"
      round
      closeable
      position="bottom"
    >
      <div class="f1">缘分，从送第一份礼物开始认识！</div>
      <van-grid
        :border="false"
        :column-num="4"
        :gutter="6"
        style="background-color: white; font-size: 12px"
        ><!--原来是4-->
        <van-grid-item v-for="(item, index) in lwlist" :key="index">
          <van-image width="48px" height="48px" :src="item.ico" />
          <div>{{ item.mc }}</div>
          <div style="color: rgb(243, 202, 165)">{{ item.jg }}豆</div>
        </van-grid-item>
      </van-grid>
    </van-popup>
    <!--<Footer />-->
  </div>
</template>

<script>
//import { Notify } from "vant";
import wx from "weixin-js-sdk"; 
import { ImagePreview } from "vant";
import { ref, getCurrentInstance, onMounted } from "vue";
export default {
  name: "Userdetail",
  setup() {
    let yllist = ref([]);
    let rzcount = ref(1);
    const active = ref(0);
    const blclick = ref(false);
    const num = ref("0"); //点赞数
    const isLike = ref(false);
    const show = ref(false);
    const show2 = ref(false);
    var blshowbak = ref(true);
    const { proxy } = getCurrentInstance();
    let rzlist = ref("");
    let mmlist = ref("");
    let lwlist = ref([
      { ico: require("../assets/lw/img15.gif"), mc: "跑车", jg: 80000 },
      { ico: require("../assets/lw/img01.jpg"), mc: "蓝钻戒", jg: 10000 },
      { ico: require("../assets/lw/img05.jpg"), mc: "生日蛋糕", jg: 3000 },
      { ico: require("../assets/lw/img21.gif"), mc: "玫瑰要么", jg: 1000 },
      { ico: require("../assets/lw/img07.jpg"), mc: "特供酒", jg: 3000 },
      { ico: require("../assets/lw/img02.jpg"), mc: "金蛋", jg: 5000 },
      { ico: require("../assets/lw/img03.jpg"), mc: "白金戒指", jg: 5000 },
      { ico: require("../assets/lw/img20.gif"), mc: "内裤", jg: 3000 },

      { ico: require("../assets/lw/img10.jpg"), mc: "法拉利", jg: 50000 },
      { ico: require("../assets/lw/img19.gif"), mc: "剃须刀", jg: 1500 },
      { ico: require("../assets/lw/img18.gif"), mc: "大钻石", jg: 10000 },
      { ico: require("../assets/lw/img22.gif"), mc: "胸罩", jg: 3000 },
    ]);
    var id = "";
    var picarr = ref([]);
    var userstate = ref("0");
    var myH = ref("0");
    var vxcode=ref('');
    onMounted(() => {
 
      vxcode.value=localStorage.getItem('vxcode');
      userstate.value = localStorage.getItem("userstate"); //获取状态

      id = proxy.$route.query.userid;
      // console.log(id);
      if (typeof id != "undefined") {
        // Notify("客户id是：" + proxy.GLOBAL.openid);
      }
      getorderlist();

      getyoulove(); //获取猜你喜欢的对象

      /* proxy.$nextTick(() => {
        // 页面渲染完成后的回调
        //console.log(proxy.$refs.getheight.offsetHeight)
        setTimeout(() => {
          myH.value = proxy.$refs.getheight.offsetHeight - 45 + "px";
          //console.log(myH.value)
        }, 300);
      }); */
    });
    const initData = () => {
      const data = {
        url: location.href.split('#')[0]
      }
      //console.log(location.href.split('#')[0]);
      proxy.$http
        .post("https://pnlove.my306.com/wxshare.php", data)
        .then((response) => {
          
          var result = response.data.data;
          //console.log(result);
          //console.log('appid是：'+result.appId)
            wx.config({
            debug: false,//生产环境需要关闭debug模式
            appId: result.appId,
            timestamp: result.timestamp,//生成签名的时间戳
            nonceStr: result.nonceStr,//生成签名的随机字符串
            signature: result.signature,//签名
            jsApiList: [
                'updateTimelineShareData','updateAppMessageShareData'
            ]
            });
 
            wx.ready(function() {
              //console.log('配置成功！')
              //alert('配置成功！');
              /*
               // 判断是否是ios微信浏览器  
 if (window.__wxjs_is_wkwebview === true) {
    url = store.state.url
  } 
  */
            var mytitle='';
            if(mmlist.value.xb=='0') 
                mytitle='【帅哥想脱单】'+mmlist.value.name+'.'+mmlist.value.age+'岁.'+mmlist.value.sg+'厘米.平南相亲网';
            else
                mytitle='【美女想脱单】'+mmlist.value.name+'.'+mmlist.value.age+'岁.'+mmlist.value.sg+'厘米.平南相亲网';
            const shareData = {
            "imgUrl": mmlist.value.imglist, //'https://pnlove.my306.com/img/mm.df3957ef.jpg',  //mmlist.value.imglist,//这里填照片，必须是绝对路径 例http https开头可以直接访问
            "title": mytitle,
            "desc": "平南相亲，为广州地区单身的小伙伴提供一个真实靠谱的相亲平台；脱单交友，同城相亲征婚。",
            'link': location.href //"https://pnlove.my306.com/"//这里要填和你js安全填的那个一致，不过这里需要加http
            };
            // wx.updateAppMessageShareData(shareData);
          //wx.updateTimelineShareData(shareData);
                wx.updateTimelineShareData(shareData);
                wx.updateAppMessageShareData(shareData);
            
            });
                wx.error(function(res) {
                  //console.log('配置失败！')
                   //alert('配置失败！');
                    //alert(res.errMsg);
                    console.log(res);
                });
        })
        .catch(() => {});
    };
    const clickpic = (index) => {
      //console.log("图片id是：" + index);
      /*if (userstate.value == "0" || userstate.value == "3") {
        proxy.$dialog
          .alert({
            title: "温馨提示",
            message:
              "真诚的对待是感情的基础，请完善您的个人信息，再查看TA的更多资料",
            theme: "round-button",
            confirmButtonText: "立即完善",
          })
          .then(() => {
            proxy.$router.push({
              path: "/myinfo",
              query: { type: "1" },
            });
            return;
            // on close
          });
        return;
      }*/
      if (userstate.value == "0") {
        proxy.$dialog
          .alert({
            title: "温馨提示",
            message:
              "您的个人资料正在审核中，待审核通过后才能查看TA的更多资料",
            theme: "round-button",
            confirmButtonText: "查看审核进度",
          })
          .then(() => {
            proxy.$router.push({
              path: "/myinfo",
              query: { type: "1" },
            });
            return;
            // on close
          });
        return;
      }
      else if(userstate.value == "3"|| userstate.value == null || userstate.value == ""){
        proxy.$dialog
          .alert({
            title: "温馨提示",
            message:
              "真诚的对待是感情的基础，请完善您的个人信息，再查看TA的更多资料",
            theme: "round-button",
            confirmButtonText: "立即完善",
          })
          .then(() => {
            proxy.$router.push({
              path: "/myinfo",
              query: { type: "1" },
            });
            return;
            // on close
          });
        return;
      }

      blshowbak.value = false;
      ImagePreview({
        images: picarr.value,
        startPosition: index,
        showIndicators: true,
        onClose() {
          blshowbak.value = true;
        },
      });
    };
    const getorderlist = () => {
      proxy.$http
        .get("https://pnlove.my306.com/pnlove/getuserinfobyid.php", {
          params: { id: id, myid: localStorage.getItem("pnxqtel"),userstate:localStorage.getItem("userstate")},
        })
        .then(function (res) {
          //console.log("来了");
          //console.log(res.data);

          vxcode.value=res.data.r3.vxcode;
          console.log(vxcode.value)
          localStorage.setItem('vxcode',vxcode.value);//为了在微信分享进入后正常显示vxcode，在这里获取一下

          mmlist.value = res.data.r1;
          var mytitle='';
            if(mmlist.value.xb=='0') 
                mytitle='【帅哥想脱单】'+mmlist.value.name+'.'+mmlist.value.age+'岁.'+mmlist.value.sg+'厘米.平南相亲网';
            else
                mytitle='【美女想脱单】'+mmlist.value.name+'.'+mmlist.value.age+'岁.'+mmlist.value.sg+'厘米.平南相亲网';
          window.document.title=mytitle;
          //mmlist.value["id"] = parseInt(mmlist.value["id"]) + 1000000;
          var imgstr = mmlist.value["imglist"];
          var imgarr = imgstr.split("|");
          mmlist.value["imglist"] = imgarr[0]; //第一张是封面
          imgarr.pop(); //移除最后一个元素
          picarr.value = imgarr;
          num.value = mmlist.value["zhan"];

          rzlist.value = res.data.r2;
          if (rzlist.value != null) {
            if (rzlist.value["sfrz"] == "1") rzcount.value++;
            if (rzlist.value["wxrz"] == "1") rzcount.value++;
            if (rzlist.value["zrrz"] == "1") rzcount.value++;
            if (rzlist.value["hyrz"] == "1") rzcount.value++;
            if (rzlist.value["xlrz"] == "1") rzcount.value++;
            if (rzlist.value["zfrz"] == "1") rzcount.value++;
            if (rzlist.value["ddrz"] == "1") rzcount.value++;
          }

          initData();
          //console.log(picarr);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    /*
    const clicksc = (index) => {
      // console.log(mmlist)
      var msg = "已经收藏~~";
      if (mmlist.value[index].blsc === "0") {
        mmlist.value[index].blsc = "1";
      } else {
        msg = "已经取消收藏~~";
        mmlist.value[index].blsc = "0";
      }
      let mybody = {
        myid: proxy.GLOBAL.openid,
        loveid: mmlist.value[index].openid,
        type: mmlist.value[index].blsc,
      };
      proxy.$http
        .post("https://pnlove.my306.com/pnlove/clicksc.php", mybody)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(() => {});
      proxy.$dialog
        .alert({
          title: "",
          message: msg,
          theme: "round-button",
        })
        .then(() => {
          // on close
        });
    };
    const clickxq = () => {
      console.log("来了");
      if (!proxy.GLOBAL.blreg) console.log("来了");
      else {
        proxy.$dialog
          .confirm({
            title: "温馨提示",
            message: "亲，查看Ta的资料请先完善您个人真实资料哦~",
          })
          .then(() => {
            // on confirm
            proxy.$router.push({ path: "/loginfo" });
          })
          .catch(() => {
            // on cancel
          });
      }
    };*/
    const clickmore = () => {
      //console.log(mmlist.value);
      var mystr = JSON.stringify(mmlist.value);
      // console.log("转换的是"+mystr)
      proxy.$router.push({ path: "/moreinfo", query: { userdata: mystr } });
    };
    const toback = () => {
      //proxy.$router.go(-1);
      if (window.history.length <= 1) {
        proxy.$router.push({path:'/index'});
        return false;
      } else {
          proxy.$router.go(-1);
      }
    };
    const concat = () => {
      show.value = true;
    };

    const totop = () => {
      proxy.$refs.target.scrollIntoView();
    };
    const buyhy = () => {
      proxy.$router.push({ path: "/buy" });
    };

    const clicksc = () => {
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
        return;
      } 

      if (userstate.value == "0") {
        proxy.$dialog
          .alert({
            title: "温馨提示",
            message:
              "您的个人资料正在审核中，待审核通过后才能查看TA的更多资料",
            theme: "round-button",
            confirmButtonText: "查看审核进度",
          })
          .then(() => {
            proxy.$router.push({
              path: "/myinfo",
              query: { type: "1" },
            });
            return;
            // on close
          });
        return;
      }
      else if(userstate.value == "3"){
        proxy.$dialog
          .alert({
            title: "温馨提示",
            message:
              "真诚的对待是感情的基础，请完善您的个人信息，再查看TA的更多资料",
            theme: "round-button",
            confirmButtonText: "立即完善",
          })
          .then(() => {
            proxy.$router.push({
              path: "/myinfo",
              query: { type: "1" },
            });
            return;
            // on close
          });
        return;
      }
      // console.log(mmlist)
      if (mmlist.value.openid == localStorage.getItem("pnxqtel")) {
        proxy.$toast("不能关注自己");
        return;
      }
      var msg = "关注成功！";
      if (mmlist.value.blsc === "0") {
        mmlist.value.blsc = "1";
      } else {
        msg = "取消关注成功！";
        mmlist.value.blsc = "0";
      }
      let mybody = {
        myid: localStorage.getItem("pnxqtel"), //openid
        loveid: mmlist.value.openid,
        type: mmlist.value.blsc,
      };
      proxy.$http
        .post("https://pnlove.my306.com/pnlove/clicksc.php", mybody)
        .then(function () {
          //console.log(response.data);
          proxy.$toast(msg);
        })
        .catch(() => {});
      /*proxy.$dialog
        .alert({
          title: "",
          message: msg,
          theme: "round-button",
        })
        .then(() => {
          // on close
        });*/
    };
    const onClickSh = () => {
      show2.value = true;
    };

    const beforeEnter = (el) => {
      // 动画开始进入前
      el.style.transform = "translate(0,0)";
    };
    const enter = (el, done) => {
      //动画执行时
      el.offsetWidth;
      el.style.transform = "translate(10px,-22px)";
      el.style.transition = "all 0.6s ease";
      done();
    };
    const afterEnter = () => {
      // 动画结束时
      isLike.value = !isLike.value;
    };
    const likeClick = () => {
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
        return;
      } 

      if (mmlist.value.openid == localStorage.getItem("pnxqtel")) {
        proxy.$toast("不能点赞自己");
        return;
      }
      if (!blclick.value) {
        let mybody = {
          id: mmlist.value.id,
        };
        proxy.$http
          .post("https://pnlove.my306.com/pnlove/clickzhan.php", mybody)
          .then(function () {
            //console.log(response.data);
            //proxy.$toast(msg);
          })
          .catch(() => {});
        blclick.value = true;
        isLike.value = true;
        num.value++;
      }
    };
    const lookxc = ({ name })  => {
      //console.log(name)
     if(name=='xc')
     {
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
        return;
      } 
     }
       /*proxy.$dialog
        .alert({
          title: "温馨提示",
          message:
            "真诚的对待是感情的基础，请完善您的个人信息，再查看TA的更多资料",
          theme: "round-button",
          confirmButtonText: "立即完善",
        })
        .then(() => {
          proxy.$router.push({
            path: "/myinfo",
            query: { type: "1" },
          });
          // on close
        });
        */

    };
    /* const getrzmc = (i) => {
      let mc = "";
      switch (i) {
        case "1":
          mc = "身份认证";
          break;
        case "2":
          mc = "微信认证";
          break;
        case "3":
          mc = "真人认证";
          break;
        case "4":
          mc = "婚况认证";
          break;
        case "5":
          mc = "学历认证";
          break;
        case "6":
          mc = "住房认证";
          break;
        case "7":
          mc = "到店认证";
          break;
      }
      return mc;
      //require('../assets/sj2.png')
    };
    const getrzico = () => {
      return require("../assets/sj2.png");
    }; */
    const morerz = () => {
      proxy.$router.push({
        path: "/renzhen",
        query: { openid: mmlist.value["openid"], title: mmlist.value["name"] },
      });
    };
    const getyoulove = () => {
      // console.log("猜你喜欢！");
      // console.log(localStorage.getItem("userxb"));
      proxy.$http
        .get("https://pnlove.my306.com/pnlove/getyoulove.php", {
          params: { xb: localStorage.getItem("userxb") }
        })
        .then(function (res) {
          yllist.value = res.data.r1;
          //console.log("猜你喜欢！")
          //console.log(res.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
     const moreyoulove = () => {
      getyoulove();
    };

    const clickyoulove = (index) => {
      //console.log(localStorage.getItem("pnxqtel") );
      //var tostr = "/userdetail?userid=" + mmlist.value[index].openid;
      //console.log(tostr)
      //proxy.$router.push({ path: '/news', query: { userId: 123 }});

      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({
          path: "/login",
        });
      } else {
        proxy.$router.replace({
        path: "/refreshme",
        query: { userid: yllist.value[index].id }
      })
        /*proxy.$router.push({
          path: "/userdetail",
          query: { userid: yllist.value[index].id },
        });*/
      }

      /*proxy.$router.push({
        path: "/userdetail",
        query: { userid: mmlist.value[index].openid },
      });*/
    };
    const clickxqk = () => {
      localStorage.setItem('showcarid',mmlist.value.openid);
       proxy.$router.push({
          path: "/xqmb",
        });
    };
    return {
      // getrzmc,
      // getrzico,
      lookxc,
      lwlist,
      onClickSh,
      clicksc,
      buyhy,
      mmlist,
      getorderlist,
      //clicksc,
      //clickxq,
      picarr,
      clickpic,
      clickmore,
      toback,
      blshowbak,
      show,
      show2,
      concat,
      totop,
      isLike,
      num,
      beforeEnter,
      enter,
      afterEnter,
      likeClick,
      blclick,
      userstate,
      myH,
      active,
      rzlist,
      morerz,
      rzcount,
      getyoulove,
      yllist,
      moreyoulove,
      clickyoulove,
      vxcode,
      initData,
      clickxqk
    };
  },
};
</script>


<style>
:root {
  --van-cell-font-size: 14px;
  --van-cell-line-height: 32px;
  --van-cell-icon-size: 20px;
}
</style>
<style scoped>
.myfixed {
  position: absolute;
  background-color: white;
  z-index: 4;
  text-align: center;
  width: 100%;
  /*height: 100px;*/
  line-height: 100px;
  margin-top: 20px;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
  font-size: 13px;
  left: 0;
}
.clickLike {
  padding: 10px 0;
  position: relative;
}
.addOne {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  color: #f1050a;
}

.bk {
  font-size: 16px;
  background-color: #f7f7f7;
  color: rgb(50, 50, 50);
}
.name {
  font-size: 20px;
  font-weight: 505;
  margin: 0px 0px 5px 0px;
}
.mycon {
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
}
.mytag {
  margin-right: 3px;
}
.mytag2 {
  margin-right: 5px;
  margin-bottom: 5px;
  box-shadow: 1px 1px 1px #f3f1f1;
}
.mytitle {
  font-size: 14px;
  font-weight: 550;
  margin: 0px 0px 18px 0px;
}
.mytitle2 {
  font-size: 14px;
  font-weight: 550;
  margin: 18px 0px 18px 0px;
}
.mycon2 {
  padding: 20px;
  box-sizing: border-box;

  background-color: white;
}
.mytxt {
  font-size: 14px;
  line-height: 26px;
  color: RGB(47, 47, 47);
}
.mytxt2 {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mytxt3 {
  color: rgb(236, 104, 122);
  font-size: 16px;
  font-weight: 500;
}
.mytxt4 {
  margin-top: 4px;
  color: rgb(102, 102, 102);
  font-size: 13px;
}
.myimg {
  margin: 0px 3px 0px 0px;
  /*pointer-events: none; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.myimg2 {
  margin: 0px 3px 0px 0px;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -ms-filter: blur(3px);
  -o-filter: blur(3px);
  /*pointer-events: none; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.mybtm {
  margin-top: 25px;
  background-color: rgb(253, 242, 237);
  padding: 25px 10px 25px 10px;
  border-radius: 10px;
}
.mybtm2 {
  margin-top: 25px;
  background-color: rgb(253, 237, 252);
  padding: 13px 10px 13px 10px;
  border-radius: 10px;
}
.totop {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 9998;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: rgba(92, 89, 85, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.toleft {
  margin-left: 4px;
  content: "";
  display: inline-block;
  height: 11px;
  width: 11px;
  border-width: 0 0 3px 3px;
  border-color: #fff;
  border-style: solid;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
}
.zhan {
  position: absolute;
  bottom: 20px;
  left: 10px;
  z-index: 9998;
  width: 50px;
  height: 28px;
  line-height: 28px;
  border-radius: 14px;
  background-color: rgba(92, 89, 85, 0.7);
  text-align: center;
  font-size: 13px;
  color: #f7f7f7;
}
.zhandone {
  position: absolute;
  bottom: 20px;
  left: 10px;
  z-index: 9998;
  width: 50px;
  height: 28px;
  line-height: 28px;
  border-radius: 14px;
  background-color: rgba(247, 175, 166, 0.7);
  text-align: center;
  font-size: 13px;
  color: #f7f7f7;
}
.txtitle {
  font-size: 18px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txinfo {
  font-size: 14px;
  text-align: center;
}

.concat {
  position: fixed;
  bottom: 150px;
  right: 5px;
  z-index: 9998;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: #ec616a;
  box-shadow: 0 0 8px #ec616a;
}
.totop2 {
  position: fixed;
  bottom: 90px;
  right: 5px;
  z-index: 9999;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: #ec616a;
  box-shadow: 0 0 8px #ec616a;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 280px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 10px;
}
.tiptxt {
  font-size: 12px;
  margin-top: 15px;
}
.tiptxt2 {
  font-size: 12px;
}
.uid {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.f1 {
  color: #ec616a;
  font-size: 14px;
  text-align: center;
  margin-top: 40px;
}
.myrow {
  display: flex;
  align-items: center;
}
.mytt {
  font-size: 24px;
  font-weight: 550;
  color: red !important;
}
.tabsel {
  color: #ec616a;
  font-weight: 550;
  font-size: 18px;
  margin-right: 20px;
}
.tabnor {
  font-size: 16px;
  margin-right: 20px;
}
.myimg3 {
  width: 20px;
  height: 20px;
}
.rzrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rzcol {
  font-size: 12px;
  color: #666666;
}
.mytitle2 {
  font-size: 14px;
  font-weight: 550;
}
.xqk{
  font-size: 12px;
    position: fixed;
    right: 10px;
    top: 8px;
    text-align: center;
    width: 42px;
    height: 100px;
    z-index: 1;
}
.xqkwz{
      text-shadow: #fff 1px 0 0, #fff 0 1px 0, #fff -1px 0 0, #fff 0 -1px 0;
    -webkit-text-shadow: #fff 1px 0 0,#fff 0 1px 0,#fff -1px 0 0,#fff 0 -1px 0;
    -moz-text-shadow: #fff 1px 0 0,#fff 0 1px 0,#fff -1px 0 0,#fff 0 -1px 0;
    *filter: Glow(color=#fff,strength=1);
    color: #666;
}
.dz{
  margin-left:20px;
  display: flex;
  align-items: center;
}
</style>
