<template>
  <div class="bk">
    <!-- <van-sticky>
      <van-row class="topbak">
        <van-col span="6" class="back" @click="back">
          <van-image
            width="24px"
            height="24px"
            :src="require('../assets/back.png')"
          />
        </van-col>
        <van-col span="12" style="text-align: center">
          <span class="nor1">相亲活动</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>
    <van-empty image="search" description="暂无相关活动" />-->
    <div class="con">
      <van-list
        style="padding: 10px; box-sizing: border-box"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="top4">
          <van-row
            v-for="(item, index2) in mmlist"
            :key="index2"
            @click="clickart(index2)"
            class="artrow"
          >
            <van-col span="7">
              <van-image
                fit="cover"
                width="100%"
                height="100px"
                radius="5px"
                :src="item.pic"
              />
            </van-col>
            <van-col span="17" class="artcol">
              <div class="artf1">{{ item.title }}</div>
              <div class="artf2">{{ item.ptime }}</div>
            </van-col>
          </van-row>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from "vue";
export default {
  name: "Party",
  setup() {
    const loading = ref(false);
    const finished = ref(false);

    const { proxy } = getCurrentInstance();
    const active = ref(0);
    const mmlist = ref([]);
    var myoption = {
      openid: "",
      pagesize: 12,
      pageindex: 0,
    };
    //var value1=ref("");   :immediate-check="false"
    //var value2=ref("");
    onMounted(() => {
      //getorderlist();
      console.log("活动来了");
    });
    const getorderlist = () => {
      myoption.openid = localStorage.getItem("pnxqtel");
      //点击登录
      proxy.$http
        .get("https://pnlove.my306.com/pnlove/getartlist2.php", {
          params: myoption,
        })
        .then(function (res) {
          console.log(res.data.r2);
          loading.value = false;
          if (res.data.r2 == "0") {
            finished.value = true;
          } else {
            //console.log("记录是" + res.data);
            mmlist.value = mmlist.value.concat(res.data.r1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const back = () => {
      proxy.$router.go(-1);
    };

    const clickme = (index) => {
      //if (proxy.GLOBAL.openid == "") {
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
      } else {
        proxy.$router.push({
          path: "/userdetail",
          query: { userid: mmlist.value[index].id },
        });
      }
    };
    const onLoad = () => {
      myoption.pageindex = myoption.pageindex + 1; //触底了下一页
      getorderlist();
      console.log("触底了");
    };
    const clickart = (index) => {
      proxy.$router.push({
        path: "/artdetail",
        query: { id: mmlist.value[index].id },
      });
    };
    return {
      clickart,
      onLoad,
      loading,
      finished,
      clickme,
      active,
      getorderlist,
      mmlist,
      myoption,
      back,
    };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  font-size: 14px;
}
.bk2 {
  padding: 20px;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}
.mytxt {
  font-size: 20px;
  margin: 30px 20px 30px 20px;
}
.back {
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
.name {
  font-size: 16px;
}
.f1 {
  font-size: 13px;
  color: #666666;
}
.con {
  height: 100vh;
  background-color: #f7f7f7;
}
.artcol {
  padding: 0px 10px 5px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.artf1 {
  display: -webkit-box;
  /* -webkit-box-orient: vertical; */
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;

  font-size: 20px;
  font-weight: 550;
  color: #444444;
}
.artf2 {
  font-size: 13px;
  color: #666666;
}
.top4 {
  margin-top: 10px;
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;

  background-color: white;
}
.artrow {
  margin-bottom: 15px;
}
</style>
