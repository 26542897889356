<template>
  <div class="bk">
    <!--<div style="display:none;"><img src="../assets/share.png" alt=""></div>-->
    <div ref="target"></div>
    <!--<van-sticky>
      <van-row class="topbak">
        <van-col span="6"></van-col>
        <van-col span="12" style="text-align: center"
          ><span class="sel1">推荐</span><span class="nor1">活动</span>
          <span class="nor1" @click="search">搜索</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>-->
    <Topnav />
    <!-- require('../assets/banner.png') -->
    <van-swipe class="my-swipe" :autoplay="25000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in lunbotu" :key="index">
        <van-image
          :src="item.myval"
          width="100%"
          height="160px"
          fit="cover"
        />
      </van-swipe-item>
      <!--<van-swipe-item>
        <van-image
          :src="require('../assets/12196.png')"
          width="100%"
          height="160px"
          fit="cover"
        />
      </van-swipe-item>-->
    </van-swipe>
    <!--<van-notice-bar
      style="height: 25px; font-size: 12px"
      mode="closeable"
      left-icon="volume-o"
      text="温馨提示：相关信息的真实性请进一步核实，相亲过程中，一定要提高保护意识，没有足够自我保护意识请别参与，涉及经济往来的切莫上当受骗。"
    />-->

    <!--<van-notice-bar
      left-icon="volume-o"
      text="温馨提示：相关信息的真实性请进一步核实，相亲过程中，一定要提高保护意识，没有足够自我保护意识请别参与，涉及经济往来的切莫上当受骗。"
    />
    <van-sticky>
      <van-tabs v-model="active">
        <van-tab title="最新"></van-tab>
        <van-tab title="附近的人"></van-tab>
        <van-tab title="最匹配">内容 3</van-tab>
        <van-tab title="我要置顶">内容 4</van-tab>
        <van-tab title="搜索">内容 4</van-tab>
      </van-tabs>
    </van-sticky>-->

    <div class="top3">
      <van-row class="topbak2">
        <van-col span="8" class="zhid">置顶推荐</van-col>
        <van-col span="16" class="shenq" @click="concat"
          >也想展示在这里？戳我 ></van-col
        >
      </van-row>
      <van-row>
        <ul class="myul">
          <li
            class="myli"
            v-for="(item, index) in zdlist"
            :key="index"
            @click="clicktop(index)"
          >
            <van-image
              fit="cover"
              radius="5"
              class="myimg2"
              width="100"
              height="120"
              :src="item.imglist"
            />
            <div
              style="
                text-align: center;
                font-size: 16px;
                margin: 5px 0px 3px 0px;
              "
            >
              {{ item.name }}
            </div>
            <div
              style="
                text-align: center;
                font-size: 12px;
                color: RGB(120, 120, 120);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >
              {{ item.age }}岁 {{ item.sg }}CM
            </div>
          </li>
        </ul>
      </van-row>
    </div>
    <div class="mybn2">推荐会员</div>
    <div class="sqzd">你的另一半已上线，还在等什么？</div>
    <div class="mybn">
      <div :class="clicksel == '0' ? 'sel' : 'nor'" @click="clicksel0">
        魅力榜
      </div>
      <div :class="clicksel == '2' ? 'sel' : 'nor'" @click="clicksel2">
        优质女会员
      </div>
      <div :class="clicksel == '1' ? 'sel' : 'nor'" @click="clicksel1">
        优质男会员
      </div>
    </div>

    <van-grid
      :border="false"
      :column-num="2"
      :gutter="16"
      style="background-color: white"
      ><!--原来是4-->
      <van-grid-item v-for="(item, index) in mmlist" :key="index">
        <div
          style="
            border: 1px solid rgb(242, 242, 242);
            width: 100%;
            border-radius: 5px;
          "
        >
          <!--grid宫格没有圆角，为了显示圆角，专门加了一层div-->
          <div @click="clickme(index)" style="width: 100%">

            <!--角标-->
              <div class="myimg4" v-if="item.sfrz=='1'">          
              </div>
            <!--角标-->
    

            <div class="zhan">{{ item.name }}</div>
            <van-image
              radius="5"
              :src="item.imglist"
              height="230"
              width="100%"
              fit="cover"
            />
            <div class="con1">
              <van-image
                class="myimg"
                width="12"
                height="12"
                :src="
                  item.xb == '0'
                    ? require('../assets/boy.png')
                    : require('../assets/girl.png')
                "
              />
              {{ item.adress }}･{{ item.age }}岁･{{ item.sg }}CM
            </div>
            <div class="con2">
              <div class="myxl">{{ item.sr }}</div>
              <div class="myxl">{{ item.zw }}</div>
              <!--<van-image
                v-show="item.car != '无车'"
                class="myimg2"
                width="15"
                height="15"
                :src="require('../assets/xc.png')"
              />
              <van-image
                class="myimg2"
                v-show="item.house != '无房'"
                width="15"
                height="15"
                :src="require('../assets/fz.png')"
              />
              <van-image
                class="myimg3"
                width="15"
                height="15"
                :src="require('../assets/flower.png')"
              />0-->
            </div>
          </div>

          <!--<van-row class="myrow">
            <van-col span="12">
              <van-row justify="center" align="center" @click="clicksc(index)">
                <van-image
                  class="myimg2"
                  width="20"
                  height="20"
                  :src="
                    item.blsc === '0'
                      ? require('../assets/sc.png')
                      : require('../assets/sc1.png')
                  "
                />收藏
              </van-row>
            </van-col>
            <van-col span="12">
              <van-row justify="center" align="center" @click="clickxq">
                <van-image
                  class="myimg2"
                  width="20"
                  height="20"
                  :src="require('../assets/xin.png')"
                />相亲
              </van-row>
            </van-col>
          </van-row>-->
        </div>
      </van-grid-item>
    </van-grid>
    <!-- <div class="sqzd" style="margin-top: 50px" @click="moreart">
      幸福热线：17776393183 
    </div> -->
    <div class="more" @click="moreuser">更多</div>
    <div class="mybn2">相亲资讯</div>
    <div class="sqzd">最新相亲活动，相亲资讯</div>
    <div class="top4">
      <van-row
        v-for="(item, index2) in artlist"
        :key="index2"
        @click="clickart(index2)"
        class="artrow"
      >
        <van-col span="7">
          <van-image
            fit="cover"
            width="100%"
            height="100px"
            radius="5px"
            :src="item.pic"
          />
        </van-col>
        <van-col span="17" class="artcol">
          <div class="artf1">{{ item.title }}</div>
          <div class="artf2">{{ item.ptime }}</div>
        </van-col>
      </van-row>
    </div>

    <!-- <van-grid
      :border="false"
      :column-num="2"
      :gutter="8"
      style="background-color: white; margin-top: 15px"
      >
      <van-grid-item>
        <div
          style="
            border: 1px solid rgb(242, 242, 242);
            width: 100%;
            border-radius: 5px;
          "
        >
          <van-image
            fit="cover"
            class="myimg2"
            width="100%"
            height="80px"
            :src="require('../assets/12196.png')"
          />
          <div class="hdtxt">2021年大型相亲活动大型相亲活动</div>
          <div class="hdcity">南宁市 <span class="myxl2">立即报名</span></div>
          <div class="hdcity">2022-1-5 19:20:23</div>
        </div>
      </van-grid-item>
      <van-grid-item>
        <div
          style="
            border: 1px solid rgb(242, 242, 242);
            width: 100%;
            border-radius: 5px;
          "
        >
          <van-image
            fit="cover"
            class="myimg2"
            width="100%"
            height="80px"
            :src="require('../assets/12196.png')"
          />
          <div class="hdtxt">2021年大型相亲活动</div>
          <div class="hdcity">南宁市<span class="myxl2">立即报名</span></div>
          <div class="hdcity">2022-1-5 19:20:23</div>
        </div>
      </van-grid-item>
    </van-grid> -->
    <div class="more" @click="moreparty">更多</div>
      <div class="sqzd" style="margin-top: 50px" @click="moreart">
      幸福热线：{{kfinfo.kftel}}
    <div
      style="
        height: 90px;
        font-size: 12px;
        color: rgb(220, 220, 220);
        text-align: center;
      "
    >
      <!--中绘信息科技提供技术支持-->
    </div>
    </div>

    <div class="concat" @click="concat">
      <van-image
        width="21px"
        height="21px"
        :src="require('../assets/hn.png')"
      />红娘
    </div>
    <div class="totop" @click="totop">
      <van-image
        width="21px"
        height="18px"
        :src="require('../assets/totop.png')"
      />顶部
    </div>

<!--require('../assets/wechat.jpg')-->
    <van-overlay :show="show" @click="show = false" style="z-index: 99999">
      <div class="wrapper">
        <div class="block" @click.stop="longtimeclick">
          <van-image
            width="160px"
            height="160px"
            :src="kfinfo.vxcode"
          />

          <div class="tiptxt">长按二维码添加红娘微信</div>
          <div class="tiptxt2">推荐对象，飞速脱单</div>
        </div>
        <van-image
          style="margin-top: 15px"
          width="40px"
          height="40px"
          :src="require('../assets/close.png')"
        />
      </div>
    </van-overlay>

    <Footer />
  </div>
</template>

<script>
//import { Notify } from "vant";
import wx from "weixin-js-sdk"; 

import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import Footer from "@/components/Footer.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  name: "Index",
  components: {
    Footer,
    Topnav,
  },
  setup() {
    //var blreg = false;
    const show = ref(false);
    var myoption = {
      //openid: "",
      //type: "",
      tel: "",
    };
    var clicksel = ref("0");
    const { proxy } = getCurrentInstance();
    let artlist = ref([]);
    let mmlist = ref([]);
    let cjlist = ref([]);
    let boylist = ref([]);
    let girllist = ref([]);
    let zdlist = ref([]);
    let kfinfo=reactive({
      kftel:'',
      vxcode:''
    })
    let lunbotu=ref([]);
    onMounted(() => {
      //不要传过来了，必须 登录才能使用
      /*let id = proxy.$route.query.userid;
      if (typeof id != "undefined") {
        proxy.GLOBAL.openid = id; //有传过来的，处理下
        Notify("客户id是：" + proxy.GLOBAL.openid);
      }*/
      initData();//先初始化，以便从微信分享
      getorderlist();
    });
      const initData = () => {
      const data = {
        url: location.href.split('#')[0]
      }
      //console.log(location.href.split('#')[0]);
      proxy.$http
        .post("https://pnlove.my306.com/wxshare.php", data)
        .then((response) => {
          
          var result = response.data.data;
          //console.log(result);
          //console.log('appid是：'+result.appId)
            wx.config({
            debug: false,//生产环境需要关闭debug模式
            appId: result.appId,
            timestamp: result.timestamp,//生成签名的时间戳
            nonceStr: result.nonceStr,//生成签名的随机字符串
            signature: result.signature,//签名
            jsApiList: [
                'updateTimelineShareData','updateAppMessageShareData'
            ]
            });
 
            wx.ready(function() {
            const shareData = {
            "imgUrl": 'https://pnlove.my306.com/img/wx.png', //'https://pnlove.my306.com/img/mm.df3957ef.jpg',  //mmlist.value.imglist,//这里填照片，必须是绝对路径 例http https开头可以直接访问
            "title": '平南相亲，广州本地真实靠谱婚恋平台',
            "desc": "平南相亲，为广州地区单身的小伙伴提供一个真实靠谱的相亲平台；脱单交友，同城相亲征婚。",
            'link': location.href //"https://pnlove.my306.com/"//这里要填和你js安全填的那个一致，不过这里需要加http
            };
            // wx.updateAppMessageShareData(shareData);
          //wx.updateTimelineShareData(shareData);
                wx.updateTimelineShareData(shareData);
                wx.updateAppMessageShareData(shareData);
            
            });
                wx.error(function(res) {
                  //console.log('配置失败！')
                   //alert('配置失败！');
                    //alert(res.errMsg);
                    console.log(res);
                });
        })
        .catch(() => {});
    };
    const totop = () => {
      proxy.$refs.target.scrollIntoView();
    };
    const getorderlist = () => {
      proxy.$http
        .get("https://pnlove.my306.com/pnlove/gethomedata2.php", {
          params: myoption,
        })
        .then(function (res) {
           console.log(res);
          mmlist.value = res.data.r1;
          cjlist.value = res.data.r1;
          boylist.value = res.data.r2;
          girllist.value = res.data.r3;
          zdlist.value = res.data.r4;
          artlist.value = res.data.r5;
          lunbotu.value=res.data.r6;
          kfinfo.kftel=res.data.r7.tel;
          kfinfo.vxcode=res.data.r7.vxcode;
          localStorage.setItem('kftel',kfinfo.kftel);//客服电话
          localStorage.setItem('vxcode',kfinfo.vxcode);//微信二维码
          //kfinfo.value=res.data.r7;
          //if (res.data.r2 == "520") proxy.GLOBAL.blreg = true;
          //console.log(lunbotu.value);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    /*
    const clicksc = (index) => {
      // console.log(mmlist)
      var msg = "已经收藏~~";
      if (mmlist.value[index].blsc === "0") {
        mmlist.value[index].blsc = "1";
      } else {
        msg = "已经取消收藏~~";
        mmlist.value[index].blsc = "0";
      }
      let mybody = {
        myid: proxy.GLOBAL.openid,
        loveid: mmlist.value[index].openid,
        type: mmlist.value[index].blsc,
      };
      proxy.$http
        .post("https://ttdj.my306.com/zhxq/clicksc.php", mybody)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(() => {});
      proxy.$dialog
        .alert({
          title: "",
          message: msg,
          theme: "round-button",
        })
        .then(() => {
          // on close
        });
    };
    const clickxq = () => {
      if (!proxy.GLOBAL.blreg) console.log("来了");
      else {
        proxy.$dialog
          .confirm({
            title: "温馨提示",
            message: "亲，查看Ta的资料请先完善您个人真实资料哦~",
          })
          .then(() => {
            // on confirm
            proxy.$router.push({ path: "/loginfo" });
          })
          .catch(() => {
            // on cancel
          });
      }
    };*/
    const clickme = (index) => {
      //if (proxy.GLOBAL.openid == "") {
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
      } else {
        proxy.$router.push({
          path: "/userdetail",
          query: { userid: mmlist.value[index].id },
        });
      }
    };
    const clicktop = (index) => {
      /*proxy.$router.push({
        path: "/userdetail",
        query: { userid: zdlist.value[index].openid },
      });*/
      //console.log(localStorage.getItem("pnxqtel"))
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({
          path: "/login",
        });
      } else {
        proxy.$router.push({
          path: "/userdetail",
          query: { userid: zdlist.value[index].id },
        });
      }
    };
    const clicksel0 = () => {
      clicksel.value = 0; //推荐
      //myoption.openid = proxy.GLOBAL.openid;
      //myoption.type = "0"; //推荐（默认）
      mmlist.value = cjlist.value;
      //dogetlist();
    };
    const clicksel1 = () => {
      clicksel.value = 1; //男生
      //myoption.openid = proxy.GLOBAL.openid;
      //myoption.type = "1"; //推荐（默认）
      mmlist.value = boylist.value;
      //dogetlist();
    };
    const clicksel2 = () => {
      clicksel.value = 2; //女生
      //myoption.openid = proxy.GLOBAL.openid;
      //myoption.type = "2"; //推荐（默认）
      mmlist.value = girllist.value;
      // dogetlist();
    };
    const clicksel3 = () => {
      clicksel.value = 3; //匹配
      //myoption.openid = proxy.GLOBAL.openid;
      //myoption.type = "3"; //推荐（默认）
      //dogetlist();
    };
    const moreuser = () => {
      proxy.$router.push({ path: "/yujian" });
    };
    const login = () => {
      proxy.$router.push({ path: "/login" });
    };
    const showme = () => {
      proxy.$router.push({
        path: "/buy",
      });
    };
    const concat = () => {
      show.value = true;
    };
    const moreparty = () => {
      proxy.$router.push({
        path: "/artlist",
      });
    };
    const moreart = () => {
      proxy.$router.push({
        path: "/artdetail",
      });
    };
    const clickart = (index) => {
      proxy.$router.push({
        path: "/artdetail",
        query: { id: artlist.value[index].id },
      });
    };
    /* const search = () => {
      proxy.$router.push({
        path: "/search",
      });
    };
    const getartlist=()=>{
        proxy.$http
        .get("https://ttdj.my306.com/zhxq/getartlist.php", {
          params: "",
        })
        .then(function (res) {
           console.log(res.data);
          artlist.value=res.data.r1;
        })
        .catch(function (error) {
          console.log(error);
        });
    }; */

    return {
      clickart,
      // search,
      artlist,
      // getartlist,
      moreart,
      moreparty,
      concat,
      showme,
      mmlist,
      zdlist,
      getorderlist,
      //clicksc,
      //clickxq,
      clickme,
      clicksel0,
      clicksel1,
      clicksel2,
      clicksel3,
      clicksel,
      clicktop,
      moreuser,
      boylist,
      girllist,
      cjlist,
      login,
      totop,
      show,
      kfinfo,
      lunbotu,
      initData
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only bk:rgb(245, 245, 245);-->
<style scoped>
.myimg {
  display: inline-block;
}

.myimg2 {
  margin-right: 3px;
}
.myimg3 {
  margin-left: 10px;
}

.myimg4{
  position:relative;
}
.myimg4::before {
position:absolute;
       content: " ";
            top: -3px; /* 调整距离顶部的距离 */
            left: -3px; /* 调整距离左边的距离 */
            width: 53px;
            height: 50px;
            background-image: url('../assets/smrz.png');
            z-index: 1;
            background-size: cover;

}
/deep/ .van-grid-item__content {
  padding: 0px;
  border-radius: 5px;
}
.bk {
  background-color: white;
}
.bb {
  border: 1px solid #6666;
}
.con1 {
  font-size: 13px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  padding: 6px 0px 0px 0px;
}
.con2 {
  font-size: 13px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 3px 10px 3px;
  flex-wrap: wrap;
}
.myrow {
  padding: 3px 10px 8px 10px;
  box-sizing: border-box;
  font-size: 15px;
}
.agecolor {
  color: #ec616a;
}
/*.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}*/
.topbak2 {
  padding: 8px 0px 8px 0px;
}
.zhid {
  font-size: 20px;
}
.shenq {
  font-size: 13px;
  text-align: right;
  color: #ec616a;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.xibao {
  color: #ec616a;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.top3 {
  padding: 10px 8px 10px 8px;
  box-sizing: border-box;

  background-color: white;
}
.mybn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 15px 30px;
  background-color: white;
}
.mybn2 {
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  color: #ec616a;
  background-color: white;
  padding: 20px 0px 8px 0px;
}
.sel {
  background-color: #ec616a;
  color: white;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 95px;
  border-radius: 18px;
  font-size: 15px;
}
.nor {
  color: black;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 95px;
  border-radius: 18px;
  border: 1px solid rgb(242, 242, 242);
  font-size: 15px;
}
/*.sel1 {
  font-weight: 510;
  margin-right: 10px;
  font-size: 22px;
}
.nor1 {
  margin-right: 10px;
  font-size: 18px;
}*/
.sqzd {
  background-color: white;
  color: #ee7f87;
  text-align: center;
  font-size: 15px;
}
ul {
  display: flex;
  width: 100%;
  height: 172px;
  background: #fff;
  box-sizing: border-box;
  /* 下面是实现横向滚动的关键代码 */
  display: inline;
  float: left;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; /*解决在ios滑动不顺畅问题*/
  overflow-y: hidden;
}
ul li {
  display: inline-block; /*设置li为行内块级元素*/
  width: 110px;
  height: 110px;
  text-align: left;
  border-radius: 6px 6px 6px 6px;
}
.xibaotop {
  padding: 10px 0px 0px 0px;
}
.zhan {
  position: absolute;
  bottom: 75px;
  left: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 16px;
}
.myxl {
  margin-right: 10px;
  background-color: #fdf7f7;
  color: #ec616a;
  border: 1px solid #fbeaea;
  height: 17px;
  line-height: 17px;
  text-align: center;
  /*width: 36px;*/
  padding: 0px 4px 0px 4px;
  border-radius: 3px;
  font-size: 13px;
}
.more {
  width: 110px;
  height: 32px;
  line-height: 32px;
  background-color: #fdf7f7;
  color: #ec616a;
  border: 1px solid #fbeaea;
  text-align: center;
  margin: 15px auto;
  border-radius: 18px;
  font-size: 14px;
}
.hdtxt {
  font-size: 14px;
  text-align: center;
  margin-top: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.hdcity {
  font-size: 12px;
  color: rgb(120, 120, 120);
  text-align: center;
}
.myxl2 {
  margin-right: 15px;
  background-color: #fdf7f7;
  color: #ec616a;
  border: 1px solid #fbeaea;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 60px;
  border-radius: 3px;
  font-size: 13px;
}
.concat {
  position: fixed;
  bottom: 150px;
  right: 5px;
  z-index: 9999;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: #ec616a;
  box-shadow: 0 0 8px #ec616a;
}
.totop {
  position: fixed;
  bottom: 90px;
  right: 5px;
  z-index: 9999;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: #ec616a;
  box-shadow: 0 0 8px #ec616a;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 280px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 10px;
}
.tiptxt {
  font-size: 12px;
  margin-top: 15px;
}
.tiptxt2 {
  font-size: 12px;
}
.my-swipe .van-swipe-item {
  height: 160px;
}
.artcol {
  padding: 0px 10px 5px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.artf1 {
  display: -webkit-box;
  /* -webkit-box-orient: vertical; */
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;

  font-size: 20px;
  font-weight: 550;
  color:#444444;
}
.artf2 {
  font-size: 13px;
  color: #666666;
}
.top4 {
  margin-top: 10px;
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;

  background-color: white;
}
.artrow{
  margin-bottom: 15px;
}
</style>
